:root {
  --grey: #707070;
  --blue: #0C0C28;
  --green: #41924B;
  --red: #C62727;
  --gold: #CDAA35;
  --silver: #D1D1D1;
  --bronse: #9A5521;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--blue);
}

input[type="text"] {
  border: 2px solid var(--blue);
  border-radius: 50px;
}

#root {
  display: flex;
  min-height: 100vh;
}

.overlay {
  position: fixed;
  top: 0vh;
  left: 0vw;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.2);
}

.overlay.show {
  display: flex;
}

.popup {
  max-width: 500px;
  background: #fff;
  padding: 20px;
  border: 2px solid var(--blue);
  border-radius: 7px;
  margin-top: 40px;
}

.popup > * {
  margin-right: 20px;
}

.popup > *:last-child {
  margin-right: 0;
}

.popup input {
  padding: 10px 20px;
  font-size: 16px;
}

.popup button {
  background-color: var(--blue);
  padding: 10px 20px;
  border-radius: 50px;

  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

nav {
  background-color: var(--blue);
  position: fixed;
  height: 100%;
}

nav a {
  display: block;
  padding: 40px 20px;
}

nav a:first-of-type {
  padding-top: 20px;
}

nav a:last-of-type {
  padding-bottom: 20px;
}

nav a img:nth-of-type(2) {
  display: none;
}

nav a.active {
  background: #fff;
}

nav a.active img:nth-of-type(1) {
  display: none;
}

nav a.active img:nth-of-type(2) {
  display: block;
}

.container {
  max-width: 1050px;
  padding: 0 20px;
  margin: 40px auto 0;
  flex-grow: 1;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

h1, h2, h3 {
  font-weight: 500;
  color: var(--blue);
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 20px;
}

header .utils {
  display: flex;
}

.search {
  width: 200px;
  padding: 10px 20px;

  font-size: 16px;
}

.utils  * {
  margin-right: 20px;
}

.utils *:last-child {
  margin-right: 0;
}

.utils .add,
.utils button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid var(--blue);
  border-radius: 50%;
  margin: 0;

  font-size: 30px;
}

.utils .back,
.utils .add {
  background-color: var(--blue);
  color: #fff;
}

.utils .back img {
  width: 19px;
}

.borderList li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--blue);
  border-radius: 7px;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;

  font-size: 30px;
  font-weight: 500;
}

.borderList li:last-of-type  a {
  margin-bottom: 0;
}

.gameList .winner img {
  width: 35px;
  margin-right: 10px;
}

.gameList .games-vs {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}

.gameList .games-vs > div {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
}

.gameList .games-vs > div:last-of-type {
  margin-right: 0;
}

.gameList .games-vs > div span:first-of-type {
  font-size: 30px;
  font-weight: 600;
}

.gameList .games-vs > div.won span:first-of-type {
  color: var(--green);
}

.gameList .games-vs > div.lost span:first-of-type {
  color: var(--red);
}

.stats {
  width: 50%;
  justify-content: space-between;
  display: flex;

  color: var(--grey);
  font-size: 20px;
}

.stats div span {
  margin-left: 5px;

  color: var(--blue);
  font-size: 24px;
  font-weight: 600;
}

.stats div.wins span {
  color: var(--green);
}

.stats div.loses span {
  color: var(--red);
}

.playerScreen {
  position: relative;
}

.playerScreen h1 {
  text-align: center;
  margin-bottom: 80px;
}

.playerScreen .stats {
  width: 200px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;

  color: var(--blue);
  font-weight: 500;
}

.playerScreen .stats > div {
  display: flex;
  flex-direction: column;
}

.playerScreen .stats > div p {
  margin-bottom: 8px;
  font-size: 18px;
}

.playerScreen .stats > div:last-of-type p {
  font-weight: 600;
}

.playerScreen .stats > div:last-of-type p:nth-of-type(2),
.playerScreen .stats > div:last-of-type p:nth-of-type(4) {
  color: var(--green)
}

.playerScreen .stats > div:last-of-type p:nth-of-type(3),
.playerScreen .stats > div:last-of-type p:nth-of-type(5) {
  color: var(--red)
}

.playerScreen .contentBlock-buttons button {
  padding: 20px;

  color: var(--blue);
  font-size: 20px;
  font-weight: 600;
}

.playerScreen .contentBlock-buttons button.active {
  background: var(--blue);

  color: #fff;
}

.playerScreen .contentBlock-inner {
  padding: 30px;
  background: var(--blue);
}

.vs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.vs .block {
  width: calc(50% - 10px);
  max-width: 300px;
  margin-right: 30px;
}

.vs > .block:nth-of-type(2) {
  margin-right: 0;
}

.vs h2 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}


.block-inner {
  background: #fff;
  padding: 20px 0;
  border-radius: 7px;
}

.block-inner p {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
  font-size: 18px;
  font-weight: 500;
}

.block-inner p:last-of-type {
  padding-bottom: 0;
}

.block-inner p span {
  font-size: 22px;
  font-weight: 600;
}

.block-inner p:nth-of-type(3) span {
  color: var(--green);
}

.block-inner p:nth-of-type(4) span {
  color: var(--red);
}

.block-inner .block-name {
  border-bottom: solid 4px var(--blue);
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
}

.vs .compare {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.vs .compareButton {
  width: 100%;
  height: 60px;
  background: var(--green);
  border-radius: 7px;
  margin-top: 40px;
  
  color: #fff;
  font-size: 25px;
}

.playerList {
  width: 250px;
}

.playerList li {
  border: 2px solid var(--blue);
  border-radius: 7px;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.playerList li:hover {
  background: var(--blue);
  color: #fff;
}

.popup .search {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--blue);
  border-radius: 0;
  margin-right: 0;
  margin-bottom: 20px;
}

.playerScreen + .overlay .search:focus {
  outline: none;
}

.hide {
  display: none!important;
}

.popup.addGame {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.popup.addGame > * {
  margin-right: 0;
}

.popup.addGame .addGameBtn {
  margin-left: auto;
  margin-right: auto;
}

.popup.addGame .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup.addGame .row p {
  font-size: 20px;
  font-weight: 600;
}

.popup.addGame .row input {
  width: 30px;
  padding: 10px;
  border-bottom: 2px solid var(--blue);
  border-radius: 7px;
  text-align: center;
  -moz-appearance: textfield;
}

.popup.addGame .row input::-webkit-outer-spin-button,
.popup.addGame .row input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.popup.addGame .gameInfoFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popup.addGame .row + .gameInfoFooter {
  margin-top: 10px;
}

.popup.addGame .gameInfoFooter .saveGame {
  margin-left: auto;
}

.popup.addGame .gameInfoFooter .delete {
  background-color: var(--red);
}

.rankList .info {
  display: flex;
  align-items: center;
}

.rankList .info .score {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 3px solid var(--blue);
  border-radius: 50%;
  margin-right: 15px;

  font-size: 20px;
  font-weight: 600;
}

.rankList li:first-of-type .info .score {
  background: var(--gold);
  border-color: var(--gold);

  color: #fff;
}

.rankList li:nth-of-type(2) .info .score {
  background: var(--silver);
  border-color: var(--silver);

  color: #fff;
}

.rankList li:nth-of-type(3) .info .score {
  background: var(--bronse);
  border-color: var(--bronse);

  color: #fff;
}

.rankList .utils button {
  width: auto;
  padding: 10px 15px;
  border-radius: 50px;
  margin-right: 10px;

  font-size: 18px;
}

.rankList .utils button:last-of-type {
  margin-right: 0;
}

.rankList .utils button.active {
  background: var(--blue);
  color: #fff;
  font-weight: 600;
}

.rankList .stats {
  width: auto;
}